<template>
  <section>
    <b-card-actions
      :show-loading="uploading || saving"
      action-collapse
      :title="$t('Importar Planilha')"
    >
      <FormulateForm
        v-show="!uploading"
        ref="uploadForm"
      >
        <b-row>
          <b-col
            md="3"
            class="mb-1"
          >
            <e-button
              variant="primary"
              :text="$t('Baixar modelo da planilha')"
              size="sm"
              :busy="loadingExampleDownload"
              @click="onDownloadXlsxExample"
            />
          </b-col>

          <b-col md="12">
            <!-- <FormulateInput
              v-if="isLoadFile"
              id="file-name"
              :value="fileName"
              type="label"
              :label="$t('XML da Nota Fiscal')"
            /> -->
            <FormulateInput
              id="xlsx-file"
              ref="xlsxFile"
              v-model="xlsxImportedFile"
              type="uploader"
              max-files="1"
              class="required"
              :theme="'list'"
              :deletable="true"
              :meta="true"
              :accept="'.xlsx'"
              :label="$t('Planilha de contas a pagar')"
              validation="required"
              :help-text="
                $t('Arraste a planilha preechida aqui (ou clique para selecionar o arquivo)')
              "
              :delete-confirm-message="
                $t(
                  'A remoção do arquivo fará com que os registros da tela sejam descartados. Deseja continuar?'
                )
              "
              @input="uploadFile"
              @delete="removeFiles"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card-actions>

    <e-filters
      v-show="hasFileImported"
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="filters-only_errors"
            v-model="filters.onlyErrors"
            type="switch"
            :label="$t('Apenas com erros?')"
          />
        </b-col>
        <b-col md="4">
          <e-store-combo
            id="filters-store"
            v-model="filters.storeId"
            :required="false"
          />
        </b-col>
        <b-col md="4">
          <e-person-search
            :id="`filters-supplier_id`"
            v-model="filters.supplierId"
            name="filterSupplierId"
            is-supplier
            :label="$t('Fornecedor')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="filters-value"
            v-model="filters.value"
            name="filtersValue"
            type="text-number"
            :label="$t('Valor')"
            currency="R$"
            :precision="2"
          />
        </b-col>
        <b-col md="2">
          <e-management-account
            id="filters-management_account_id"
            v-model="filters.managementAccountId"
            name="filtersManagementAccount"
            :only-active="false"
          />
        </b-col>
        <b-col md="2">
          <e-payment-method
            id="filters-payment_method_id"
            v-model="filters.paymentMethodId"
            name="filtersPaymentMethodId"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      v-show="hasFileImported"
      id="tableCard"
      no-actions
      :show-loading="fetching || saving"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <FormulateForm ref="tableForm">
        <accounts-payable-bulk-table
          :items="getAccountsPayable"
          :paging="paging"
          :summary="getItemsSummary"
        />
      </FormulateForm>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="accounts-payable-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <e-crud-actions
      v-if="hasFileImported"
      :busy="saving"
      @save="onSaveImport"
      @cancel="onCancelImport"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BRow, BCol, BPagination } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import { downloader } from '@/mixins'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import EFilters from '@/views/components/EFilters.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import ECrudActions from '@/views/components/ECrudActions.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'
import EManagementAccount from '@/views/components/inputs/EManagementAccount.vue'
import AccountsPayableBulkTable from './components/AccountsPayableBulkTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BCardActions,
    EButton,
    EPageSizeSelector,
    EPaginationSummary,
    AccountsPayableBulkTable,
    EFilters,
    EStoreCombo,
    ECrudActions,
    EPersonSearch,
    EPaymentMethod,
    EManagementAccount,
  },

  mixins: [downloader],

  data() {
    return {
      saving: false,
      uploading: false,
      loadingExampleDownload: false,
      fetching: false,
      accountPayablesXlsxFile: null,
    }
  },

  computed: {
    ...mapGetters('pages/financial/accountsPayable/accountsPayableBulkMaintain', [
      'getAccountsPayable',
      'getItemsSummary',
      'isValidToSave',
    ]),

    ...mapState('pages/financial/accountsPayable/accountsPayableBulkMaintain', {
      accountsPayableImportedFiltered: 'accountsPayableImportedFiltered',
      filters: 'filters',
      paging: 'paging',
      stXlsxImportedFile: 'xlsxImportedFile',
    }),

    xlsxImportedFile: {
      get() {
        return this.stXlsxImportedFile
      },
      set(val) {
        this.stSetXlsxImportedFile(val)
      },
    },

    hasFileImported() {
      return !this.uploading && this.xlsxImportedFile?.length > 0
    },
  },

  async mounted() {
    await this.fetchManagementAccounts()
  },

  methods: {
    ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
    ...mapActions('pages/financial/accountsPayable/accountsPayableBulkMaintain', {
      stFilterAccountsPayable: 'filterAccountsPayable',
      stSetXlsxImportedFile: 'setXlsxImportedFile',
      stSendXlsxAccountPayable: 'sendXlsxAccountPayable',
      stExampleXlsxDownload: 'exampleXlsxDownload',
      stSaveBulkImport: 'saveBulkImport',

      resetFilters: 'resetFilters',
      setCurrentPage: 'setCurrentPage',
      setPageSize: 'setPageSize',

      stCleanState: 'cleanState',
    }),

    async getData() {
      try {
        this.fetching = true
        await new Promise(resolve => setTimeout(() => resolve(), 300))
        await this.stFilterAccountsPayable()

        if (this.$refs.tableForm) this.$refs.tableForm.showErrors()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async resetFiltersLocal() {
      try {
        this.fetching = true
        await new Promise(resolve => setTimeout(() => resolve(), 300))
        await this.resetFilters()

        if (this.$refs.tableForm) this.$refs.tableForm.showErrors()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async onSaveImport() {
      try {
        this.saving = true

        if (!this.isValidToSave) {
          this.showInvalidDataMessage({
            message: this.$tc(
              'ACCOUNTS_PAYABLE.UI.PAGE_XLSX_IMPORT_SAVE.HAS_INVALID_REGISTERS',
              this.getItemsSummary.totalErrors,
              {
                totalErrors: this.getItemsSummary.totalErrors,
              }
            ),
          })
          return
        }

        await this.stSaveBulkImport()
        this.showSuccess({ message: this.$t('Dados da importação salvos com sucesso.') })
        this.stCleanState()
        this.$router.push({ name: 'accounts-payable-list' })
      } catch (error) {
        if (error.response?.data?.Message) {
          const errorDataList = JSON.parse(error.response?.data?.Message[0])
          const errorMessage = Array.isArray(errorDataList)
            ? errorDataList
                .map(msg => `- ${this.$t(msg?.message, { line: msg?.line })}`)
                .join('<br>')
            : ''
          this.showError({ message: errorMessage })
        } else {
          this.showError({ error })
        }
      } finally {
        this.saving = false
      }
    },
    onCancelImport() {
      this.stCleanState()
      this.$router.push({ name: 'accounts-payable-list' })
    },

    async uploadFile() {
      if (!this.xlsxImportedFile?.length) return

      try {
        this.uploading = true
        await this.stSendXlsxAccountPayable({ file: this.xlsxImportedFile[0], storeId: this.store })
        await this.getData()
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível fazer a leitura da Planilha de contas a pagar.'),
        })
        this.stCleanState()
      } finally {
        this.uploading = false
      }
    },
    async removeFiles() {
      this.stCleanState()
      this.xlsxImportedFile = []
    },

    async onDownloadXlsxExample() {
      try {
        this.loadingExampleDownload = true

        const blobData = await this.stExampleXlsxDownload()

        if (blobData) {
          this.forceFileDownloadXlsx(blobData, this.$t('exemplo_importacao_contas_a_pagar.xlsx'))
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao realizar o download da NFe')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingExampleDownload = false
      }
    },
  },
}
</script>

<style></style>
