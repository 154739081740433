<template>
  <div>
    <b-table
      ref="bills-bulk-table"
      show-empty
      striped
      small
      responsive
      :class="{
        bordered: true,
        'mb-1': true,
      }"
      :tbody-tr-class="rowClass"
      :fields="fields"
      :items="items"
      :empty-text="
        getEmptyTableMessage($tc('ACCOUNTS_PAYABLE.UI.PAGE_XLSX_IMPORT_SAVE.NAME'), 'female')
      "
      :current-page="paging.currentPage"
      :per-page="paging.pageSize"
    >
      <template #cell(actions)="{ item }">
        <e-grid-actions
          :show-update="false"
          :show-delete="false"
          :buttons="gridActions"
          @show-details="onShowDetails(item)"
        />
      </template>

      <template #cell(storeId)="{ item, index }">
        <e-store-combo
          :id="`bulk_table-store_id_${index}`"
          v-model="item.storeId"
          :name="`storeId_${index}`"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          :show-label="false"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(statementDate)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-statement_date_${index}`"
          v-model="item.statementDate"
          :name="`statementDate_${index}`"
          type="datepicker"
          :placeholder="$t('dd/MM/aaaa')"
          validation="required"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(dueDate)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-due_date_${index}`"
          v-model="item.dueDate"
          :name="`dueDate_${index}`"
          type="datepicker"
          :placeholder="$t('dd/MM/aaaa')"
          validation="required"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(description)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-description_${index}`"
          v-model="item.description"
          :name="`description_${index}`"
          type="textarea"
          validation="required"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template #cell(supplierId)="{ item, index }">
        <e-person-search
          :id="`bulk_table-supplier_id_${index}`"
          v-model="item.supplierId"
          :name="`supplierId_${index}`"
          is-supplier
          :show-label="false"
          required
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          :default-options="item.defaultSupplierOptions"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(documentType)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-document_type_${index}`"
          v-model="item.documentType"
          :name="`documentType_${index}`"
          type="vue-select"
          :options="documentTypeOptions()"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          validation="required"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(document)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-document_${index}`"
          v-model="item.document"
          :name="`document_${index}`"
          type="text"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          validation="required"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template #cell(managementAccountId)="{ item, index }">
        <e-management-account
          :id="`bulk_table-management_account_id-${index}`"
          v-model="item.managementAccountId"
          :name="`managementAccount_${index}`"
          label=""
          required
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          :show-label="false"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(paymentMethodId)="{ item, index }">
        <e-payment-method
          :id="`bulk_table-payment_method_id-${index}`"
          v-model="item.paymentMethodId"
          :name="`paymentMethodId_${index}`"
          label=""
          required
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          :show-label="false"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(value)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-value_${index}`"
          v-model="item.value"
          :name="`value_${index}`"
          type="text-number"
          currency="R$"
          :precision="2"
          :validation-messages="{
            required: $t('Obrigatório'),
            min: $t('Deve ser maior que 0.01'),
          }"
          validation="required|min:0.01"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template #cell(comments)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-comments-${index}`"
          v-model="item.comments"
          :name="`comments_${index}`"
          type="textarea"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template #cell(installmentComment)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-installment_comment-${index}`"
          v-model="item.installmentComment"
          :name="`installmentComment_${index}`"
          type="textarea"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template #row-details="{ item, index }">
        <div
          style="margin-left: 150px"
          class="bg-light p-1 rounded"
        >
          <p class="h4">
            {{ $t('Dados importados da planilha') }}
          </p>

          <b-row class="mb-1">
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-store_id-${index}`"
                v-model="item.xlsxData.storeId"
                :label="$t('Loja')"
                type="label"
                :errors="item.xlsxErrors['StoreId'] ? item.xlsxErrors['StoreId'] : []"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-statement_date-${index}`"
                v-model="item.xlsxData.statementDate"
                :label="$t('Data Emissão')"
                type="label"
                :errors="item.xlsxErrors['StatementDate'] ? item.xlsxErrors['StatementDate'] : []"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-due_date-${index}`"
                v-model="item.xlsxData.dueDate"
                :label="$t('Data vencimento')"
                type="label"
                :errors="item.xlsxErrors['DueDate'] ? item.xlsxErrors['DueDate'] : []"
              />
            </b-col>
            <b-col md="2">
              <div class="d-flex align-items-center">
                <FormulateInput
                  :id="`bulk_table_xlsx-supplier_document-${index}`"
                  v-model="item.xlsxData.supplierDocument"
                  :label="$t('Documento do fornecedor')"
                  type="label"
                  :errors="
                    item.xlsxErrors['SupplierDocument'] ? item.xlsxErrors['SupplierDocument'] : []
                  "
                />
              </div>
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-document_type-${index}`"
                v-model="item.xlsxData.documentType"
                :label="$t('Tipo documento')"
                type="label"
                :errors="item.xlsxErrors['DocumentType'] ? item.xlsxErrors['DocumentType'] : []"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-document-${index}`"
                v-model="item.xlsxData.document"
                :label="$t('Documento')"
                type="label"
                :errors="item.xlsxErrors['Document'] ? item.xlsxErrors['Document'] : []"
              />
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-description-${index}`"
                v-model="item.xlsxData.description"
                :label="$t('Descrição')"
                type="label"
                :errors="item.xlsxErrors['Description'] ? item.xlsxErrors['Description'] : []"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-management_account-${index}`"
                v-model="item.xlsxData.managementAccountName"
                :label="$t('Nome da conta gerencial')"
                type="label"
                :errors="
                  item.xlsxErrors['ManagementAccountName']
                    ? item.xlsxErrors['ManagementAccountName']
                    : []
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-payment_method-${index}`"
                v-model="item.xlsxData.paymentMethodName"
                :label="$t('Forma de pagamento')"
                type="label"
                :errors="
                  item.xlsxErrors['PaymentMethodName'] ? item.xlsxErrors['PaymentMethodName'] : []
                "
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-value-${index}`"
                v-model="item.xlsxData.value"
                :label="$t('Valor')"
                type="label"
                :errors="item.xlsxErrors['Value'] ? item.xlsxErrors['Value'] : []"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-comments-${index}`"
                v-model="item.xlsxData.comments"
                :label="$t('Observação')"
                type="label"
                :errors="item.xlsxErrors['Comments'] ? item.xlsxErrors['Comments'] : []"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                :id="`bulk_table_xlsx-installment_comment-${index}`"
                v-model="item.xlsxData.installmentComment"
                :label="$t('Observação da parcela')"
                type="label"
                :errors="
                  item.xlsxErrors['InstallmentComment'] ? item.xlsxErrors['InstallmentComment'] : []
                "
              />
            </b-col>
          </b-row>
        </div>
      </template>

      <template #custom-foot>
        <tr>
          <th
            colspan="10"
            class="text-right"
          >
            {{ `${$t('Correção pendente')}: ${summary.totalErrors}` }}
          </th>
          <th class="text-right">
            Total
          </th>
          <th class="text-center">
            {{ summary.totalItems | currency }}
          </th>
          <th colspan="2" />
        </tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { BTable, BCol, BRow } from 'bootstrap-vue'
import { mapState } from 'vuex/'
import { financialDomain, formulateValidation, translate } from '@/mixins'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'
import EManagementAccount from '@/views/components/inputs/EManagementAccount.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EGridActions from '@/views/components/EGridActions.vue'

export default {
  components: {
    BTable,
    BCol,
    BRow,
    EPaymentMethod,
    EManagementAccount,
    EPersonSearch,
    EStoreCombo,
    EGridActions,
  },

  mixins: [formulateValidation, translate, financialDomain],

  props: {
    items: {
      type: Array,
      required: true,
    },
    paging: {
      type: Object,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('pages/financial/accountsPayable', ['selectedInstallments']),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'actions',
          thStyle: { minWidth: '100px', width: '100px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('#'),
          key: 'xlsxData.line',
          thStyle: { minWidth: '50px', width: '50px' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: this.$t('Loja'),
          key: 'storeId',
          thStyle: { minWidth: '150px', width: '150px' },
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Dt. emis.'),
          key: 'statementDate',
          thStyle: { minWidth: '150px', width: '150px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Dt. venc.'),
          key: 'dueDate',
          thStyle: { minWidth: '150px', width: '150px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          thStyle: { minWidth: '300px', width: '300px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Fornecedor'),
          key: 'supplierId',
          thStyle: { minWidth: '300px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Tipo documento'),
          key: 'documentType',
          thStyle: { minWidth: '110px', width: '110px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Documento'),
          key: 'document',
          thStyle: { minWidth: '110px', width: '110px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Conta gerencial'),
          key: 'managementAccountId',
          thStyle: { minWidth: '160px', width: '160px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Forma de pagamento'),
          key: 'paymentMethodId',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { minWidth: '160px', width: '160px' },
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { minWidth: '110px', width: '110px' },
          formatter: val => this.$options.filters.currency(val),
          sortable: true,
        },
        {
          label: this.$t('Observação'),
          key: 'comments',
          thStyle: { minWidth: '190px', width: '190px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Observação Parc.'),
          key: 'installmentComment',
          thStyle: { minWidth: '190px', width: '190px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ]
    },

    gridActions() {
      return [
        {
          icon: 'journal-text',
          variant: 'primary',
          title: this.$t('Ver dados da importação'),
          event: 'show-details',
        },
      ].filter(btn => !btn.hide)
    },
  },

  mounted() {},

  methods: {
    // eslint-disable-next-line func-names
    onBlurField: debounce(function (item) {
      /* eslint-disable no-param-reassign */
      item.hasErrors = this.hasRowErrors(item)
      item.recordType = this.recordtypeEnum.MANUAL
      /* eslint-disable no-param-reassign */
    }, 500),
    hasRowErrors(item) {
      return (
        item.storeId == null ||
        item.statementDate == null ||
        item.dueDate == null ||
        item.supplierId == null ||
        item.description == null ||
        item.managementAccountId == null ||
        item.paymentMethodId == null ||
        item.value == null ||
        item.document?.trim() === '' ||
        item.document == null ||
        item.documentType?.trim() === '' ||
        item.documentType == null
      )
    },

    onShowDetails(item) {
      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      item._showDetails = !item._showDetails
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return ''
      return item?.hasErrors ? 'table-danger' : ''
    },
  },
}
</script>

<style lang="scss"></style>
